import { template as template_f15c8381271f4adf9d65624365daed75 } from "@ember/template-compiler";
const FKText = template_f15c8381271f4adf9d65624365daed75(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
