import { template as template_94f806f176384070ae6ca20cc8b42ab6 } from "@ember/template-compiler";
const WelcomeHeader = template_94f806f176384070ae6ca20cc8b42ab6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
